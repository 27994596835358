var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Flat Picker" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c("h5", { staticClass: "font-weight-bold" }, [
                    _vm._v(" Default ")
                  ]),
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    model: {
                      value: _vm.dateDefault,
                      callback: function($$v) {
                        _vm.dateDefault = $$v
                      },
                      expression: "dateDefault"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c("h5", [_vm._v("Time picker")]),
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      config: {
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i"
                      }
                    },
                    model: {
                      value: _vm.timePicker,
                      callback: function($$v) {
                        _vm.timePicker = $$v
                      },
                      expression: "timePicker"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c("h5", [_vm._v("Date & TIme")]),
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      config: { enableTime: true, dateFormat: "Y-m-d H:i" }
                    },
                    model: {
                      value: _vm.dateNtim,
                      callback: function($$v) {
                        _vm.dateNtim = $$v
                      },
                      expression: "dateNtim"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c("h5", [_vm._v("Multiple Dates")]),
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      config: { mode: "multiple", dateFormat: "Y-m-d" }
                    },
                    model: {
                      value: _vm.multiDate,
                      callback: function($$v) {
                        _vm.multiDate = $$v
                      },
                      expression: "multiDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c("h5", [_vm._v("Range")]),
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: { config: { mode: "range" } },
                    model: {
                      value: _vm.rangeDate,
                      callback: function($$v) {
                        _vm.rangeDate = $$v
                      },
                      expression: "rangeDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c("h5", [_vm._v("Human Friendly")]),
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      config: {
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d"
                      }
                    },
                    model: {
                      value: _vm.humanDate,
                      callback: function($$v) {
                        _vm.humanDate = $$v
                      },
                      expression: "humanDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c("h5", [_vm._v("Disabled Range")]),
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      config: {
                        dateFormat: "Y-m-d",
                        disable: [{ from: "2020-08-20", to: "2020-08-25" }]
                      }
                    },
                    model: {
                      value: _vm.disableDate,
                      callback: function($$v) {
                        _vm.disableDate = $$v
                      },
                      expression: "disableDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c("h5", [_vm._v("Inline")]),
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: { config: { inline: true } },
                    model: {
                      value: _vm.inlineDate,
                      callback: function($$v) {
                        _vm.inlineDate = $$v
                      },
                      expression: "inlineDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }